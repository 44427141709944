.header{
    font-family: 'GothamPro';
}

.ul-header{
    color: black;
    display: flex;
    width: 590px;
    font-family: 'GothamPro';
    font-size: normal;   
    line-height: 14px,    
}

.vertical-line{
    height: 24px;
    border-left: 1px solid black;
    position: static;
    flex: none;
}

.header-menu{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.header-menu li{
    margin: 0px 1.5em;
    padding: unset;
    align-items: center;
}


#main-page-button{

    font-family: 'GothamPro';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 13px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: black;
}


#information-button{

    font-family: 'GothamPro';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 13px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: black;
}

#schedule-button{

    font-family: 'GothamPro';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 13px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: black;
}

#award-button{

    font-family: 'GothamPro';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 13px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: black;
}

.enter-header-button{
    font-family: 'GothamPro';
    text-transform: uppercase;
    font-size: 14px;
    top: 36px;
    padding: 10px;
    background-color: #25BA00;
    margin-top: 3px;
}

.enter-header-button a{
    color: white;
    font-weight: bold;
    letter-spacing: 0.03em;
    line-height: 13px;
}

.header-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
}

.header-socials{
    margin: 0px .3em;
    width: 24px;
    height: 24px;
}

.header-content ul{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header-content ul li span{
    margin: 0px .3em;
}

.header-content-label {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.03em
}

.header-content-value {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.03em;
    font-weight: bold;
}

@media screen and (max-width: 1200px) {
    .follow-area, .header-content {
        display: none;
    }
}

@media screen and (max-width: 991px) {
    .header-container{
        height: 45px;
    }
}

@media screen and (max-width: 430px){
    .header-container {
        margin: unset;
    }
}

