/*
    Modal Window for information
*/
.inform-contaier {
    margin-top: 25px;
}

.full-info-modal {
    font-family: 'GothamPro';
    font-weight: 400;
    border-radius: 10px;
    border: 1px solid black;
    box-shadow: 0px 0px 13px 5.7px #44444477;
    background-color: #fff;
    z-index: 999999999;
    width: 60%;
    height: 600px;
    overflow: scroll;
    left: 20%;
    top: 5%;
    position: fixed;
    padding: 1.5em 1.5em;
    z-index: 99;
}

.full-info-modal div>span {
    position: relative;
    font-weight: bold;
}

@media screen and (max-width: 576px) {
    .full-info-modal {
        width: 90%;
        left: 5%;
        padding: 1em 1em;
    }
}

/*
    Events style css
*/

.events,
.grade {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    border: 1px;
}

.inside-container {
    width: 100%;
    border: 1px solid #25BA00;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 1.5em 0px;
}


.inside-container ul li {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: 'GothamPro';
    font-style: normal;
    font-size: 20px;
    letter-spacing: 0.03em;
}

.inside-container .events-repeateable {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.inside-container .events-repeateable ul {
    margin: 0px 1em;
}

.inside-container ul li p {
    font-weight: bold;
}

.event-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 342px;
    flex-wrap: wrap;
    background-color: #F3FFF4;
    border: 1px solid #25BA00;
}

.event-item .event-item-value {
    font-weight: bold;
}

.inside-container .event-item li {
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5em 0px;
}

@media screen and (max-width: 1200px) {
    .inside-container .events-repeateable {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .inside-container .events-repeateable ul {
        margin: .5em 0px
    }
}

@media screen and (max-width: 576px) {
    .inside-container {
        width: 90%;
        border: none;
    }

    .inside-container ul li {
        font-size: 17px;
    }

    .information-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .inside-container .events-repeateable ul {
        margin: .5em 0px
    }

    .achivements-title {
        font-size: 18px;
    }
}

@media screen and (max-width: 321px) {
    .event-item {
        width: 250px;
    }
}

.event-progress {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: .7em 0px;
}

.event-progress .event-progress-label {
    font-family: 'GothamPro';
    font-size: 20px;
    letter-spacing: 0.03em;
    width: fit-content;
    margin: 0px .5em;
    text-align: center;
}

#fullGrade {
    text-transform: uppercase;
    font-weight: bold;
}

@media screen and (max-width: 768px) {
    .event-progress {
        flex-direction: column;
    }

    .event-progress .event-progress-label {
        font-size: 18px;
        margin: .5em 0px;
    }
}

.item-box {

    border: 1px solid;
    border-radius: 15px;
    margin-bottom: 10px;
    padding: 10px;
}

.achivements-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 576px) {
    .achivements-header {
        flex-direction: column;
        align-items: flex-start;
    }
}

.information-backround {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(135, 135, 135, 0.8);
}

.information-title {
    padding-left: unset;
    margin-left: auto;
    margin-right: auto;
    width: 250px;
}

.information-title span {
    font-size: 30px;
    text-transform: uppercase;
    border-bottom: 1px solid #25BA00;
}

@media screen and (max-width: 576px) {
    .information-title span {
        font-size: 25px;
    }
}

.information-for-what>span {
    font-size: 20px;
}


.information-for-what ul {
    font-size: 18px;
    color: #2D2D2D;
}

.information-for-what ul li {
    align-items: center;
    padding: 0.5em 0px;
}

@media screen and (max-width: 576px) {
    .information-for-what {
        text-align: center;
    }

    .information-for-what>span {
        font-size: 16px;
    }

    .information-for-what ul {
        font-size: 14px;
    }
}


.information-bonuses {
    padding-top: 1.5em;
}

.information-bonuses>span {
    font-size: 20px;
    color: #2D2D2D;
}

.information-bonuses ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 18px;
}

.information-bonuses ul li {
    padding: 1em 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.information-bonuses ul li span {
    color: #25BA00;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    padding-right: 10px;
}

.information-bonuses ul li div {
    width: fit-content;
    display: flex;
    flex-direction: row;
    padding-right: 10px;
}

.information-bonuses .information-bonuses-text {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 576px) {
    .information-bonuses {
        text-align: center;
    }

    .information-bonuses>span {
        font-size: 16px;
    }

    .information-bonuses ul {
        font-size: 14px;
    }

    .information-bonuses ul li {
        text-align: center;
        align-items: center;
    }

    .information-bonuses-text {
        align-items: center;
        text-align: center;
    }

    .more-info-container {
        text-align: center;
    }

    .more-info {
        font-size: 17px;
        text-align: center;
        margin: 0px auto;
    }
}

.point {
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: #25BA00;
    background-color: #25BA00;
    margin: 2px 5px;
}

.close-button {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
}

.close-button:hover {
    opacity: 1;
}

.close-button:before,
.close-button:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
}

.close-button:before {
    transform: rotate(45deg);
}

.close-button:after {
    transform: rotate(-45deg);
}

@media screen and (max-width: 576px) {
    .close-button {
        right: 20px;
        top: 15px;
        width: 16px;
        height: 16px;
    }

    .close-button:before,
    .close-button:after {
        height: 20px;
    }
}


@media screen and (max-width: 430px) {
    .close-button {
        right: 20px;
        top: 10px;
        width: 10px;
        height: 10px;
    }
}