#auth-logo{
    width: 144px;
    height: 96px;
}

.auth-backround{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(37, 186, 0, 0.1);
    display: flex;
}

.auth-form{
    width: 35%;
    height: 500px;
    background-color: white;
    margin: 0px auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    align-self: center;
    border-radius: 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    
}

.auth-input-label{
    font-family: "GothamPro";
    font-size: 14px;
    color: black;
    width: 80%;
    display: block;
}

.auth-input{
    height: 40px;
    width: 100%;
    background: #f2f2f2;
    border-color: rgba(128, 128, 128, 0.829);
    border-radius: 10px;
    padding: 0px 5px;
    color: black;
}

.auth-input:focus {
    border: 1px solid #25BA00;
    background-color: #f2f2f2;
}

.auth-button{
    background: #25BA00;
    color: white;
    border-radius: 10px;
    align-self: center;

    max-width: 50%;
    font-family: 'GothamPro';
    font-size: 16px;
    font-weight: 700;

}

.show-false{
    font-family: 'GothamPro';
    font-size: 12px;
    line-height: 15px;
    color: red;
    text-align: center;
}

@media screen and (max-width: 786px) {
    .auth-form{
        width: 60%;
    }
}

@media screen  and (max-width: 567px) {

    .auth-form{
        width: 80%
    }

    #auth-logo{
        transform: scale(0.7, 0.7);
    }

    .auth-button{
        padding: .5em 2em;
    }
}

@media screen and (max-width: 346px) {
    .auth-form{
        height: 400px;
        margin-top: 20%;
    }
}


@media screen and (min-width: 1600px) {

    #auth-logo{
        transform: scale(1.2, 1.2)
    }

    .auth-form{
        height: 700px;
    }

    .auth-input-label{
        font-size: 22px;
    }

    .auth-input{
        height: 60px;
    }

    .auth-button{
        font-size: 26px;
        height: 60px;
    }
}