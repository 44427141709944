	/*
  	Flaticon icon font: Flaticon
  	Creation date: 21/10/2020 05:51
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
font-style: normal;
}

.flaticon-calendar:before { content: "\f100"; }
.flaticon-location:before { content: "\f101"; }
.flaticon-weighing-machine:before { content: "\f102"; }
.flaticon-height:before { content: "\f103"; }
.flaticon-biography:before { content: "\f104"; }
.flaticon-bar-chart:before { content: "\f105"; }
.flaticon-football-club:before { content: "\f106"; }
.flaticon-trophy:before { content: "\f107"; }
.flaticon-play-button-arrowhead:before { content: "\f108"; }
.flaticon-add:before { content: "\f109"; }
.flaticon-play-button:before { content: "\f10a"; }
.flaticon-check:before { content: "\f10b"; }
.flaticon-thumbs-up:before { content: "\f10c"; }
.flaticon-exchange:before { content: "\f10d"; }
.flaticon-search-interface-symbol:before { content: "\f10e"; }
.flaticon-football:before { content: "\f10f"; }
.flaticon-footwear:before { content: "\f110"; }
.flaticon-soccer-jersey:before { content: "\f111"; }
.flaticon-angle-pointing-to-left:before { content: "\f112"; }
.flaticon-angle-arrow-pointing-to-right:before { content: "\f113"; }
.flaticon-up-arrow-angle:before { content: "\f114"; }
.flaticon-down-arrow:before { content: "\f115"; }
.flaticon-clock:before { content: "\f116"; }
.flaticon-quote:before { content: "\f117"; }
.flaticon-call:before { content: "\f118"; }
.flaticon-email:before { content: "\f119"; }