.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
    margin-left: 15px;
    padding-top: 5px;
    margin-right: 2em;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 5px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #25BA00;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #25BA00;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(23px);
    -ms-transform: translateX(23px);
    transform: translateX(23px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .chart-with-switcher{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

@media screen and (max-width: 767px) {
    .chart-with-switcher{
        width: 93%;
        justify-content: space-between;
      }
    .switch {
        margin-left: 0px;
        margin-right: unset;
    }
}